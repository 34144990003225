import React from "react";
import PropTypes from "prop-types";
import pdf from "./img/ic-pdf.svg";
import doc from "./img/ic_doc.svg";
import exl from "./img/ic_exl.svg";
import "./file.scss";
import cn from "classnames";

const format = {
	pdf: pdf,
	doc: doc,
	docx: doc,
	xlsx: exl,
	xls: exl
};

const File = props => {
	const { type, name, size, link, className } = props;
	return (
		<a
			rel="noopener noreferrer"
			href={link}
			className={cn("file", className)}
			download
		>
			<img alt="" src={format[type]} className="file__icon" />
			<div className="file__properties">
				<div className="file__properties__name">{name}</div>
				<div className="file__properties__size">{size}</div>
			</div>
		</a>
	);
};

File.propTypes = {
	type: PropTypes.oneOf(["pdf", "doc", "xlsx", "xls", "docx"]),
	name: PropTypes.string,
	size: PropTypes.string,
	link: PropTypes.string
};

export default File;
