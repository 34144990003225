import "./bottom-line-link.scss";
import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";

const BottomLineWithLink = ({ title = "", to = "/" }) => (
	<section className="full-width bottom-line-with-link">
		<div className="grid-container">
			<div className="row">
				<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
					<Link to={to} className="link-l bottom-line-with-link__link">
						{title} ⟶
					</Link>
				</div>
			</div>
		</div>
	</section>
);

BottomLineWithLink.prototype = {
	title: PropTypes.string,
	to: PropTypes.string
};

export default BottomLineWithLink;
