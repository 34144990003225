import React from "react";
import OpenInfoPage from "@page-contents/open-info";
import BottomLineWithLink from "@components/bottom-line-with-link";

export default () => (
	<>
		<OpenInfoPage />
		<BottomLineWithLink title="Стать партнером" to="/about/became-partner" />
	</>
);
