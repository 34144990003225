import React from "react";

import "./open-info.scss";
import CirclePlus from "@components/circle-plus";

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from "react-accessible-accordion";

import { graphql, StaticQuery } from "gatsby";
import File from "@components/file/file";

const OpenInfo = ({ data }) => {
	const { allStrapiPublicinformations: { nodes: collection } = {} } = data;
	return (
		<section className="full-width open-info">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2>Открытая информация</h2>
					</div>
				</div>
			</div>

			<Accordion
				allowZeroExpanded
				allowMultipleExpanded
				className="accordion accordion_theme_docs"
			>
				{collection &&
					collection.map(item => {
						let i = 0;
						const { name_of_bundle, id, documents } = item;
						return (
							<AccordionItem key={id}>
								<AccordionItemHeading>
									<AccordionItemButton>
										<div className="grid-container">
											<div className="row">
												<div className="col-sm-1 hidden-md hidden-xs">
													<CirclePlus />
												</div>
												<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-10">
													<div className="container">
														<CirclePlus className="hidden-sm" />
														<span>{name_of_bundle}</span>
													</div>
												</div>
											</div>
										</div>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="grid-container">
										<div className="row">
											{documents.map(doc => {
												i += 1;
												const {
													id,
													file: {
														extension,
														prettySize: size,
														publicURL: path
													} = {},
													name
												} = doc;
												return (
													<div
														key={id}
														className={`col-xs-12 ${
															i % 2
																? "col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5"
																: "col-md-4 col-sm-5"
														}`}
													>
														<File
															name={name}
															type={extension}
															size={size}
															link={path}
															className="open-info__file"
														/>
													</div>
												);
											})}
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
						);
					})}
			</Accordion>
		</section>
	);
};

export default props => (
	<StaticQuery
		query={graphql`
			query {
				allStrapiPublicinformations(sort: { fields: order }) {
					nodes {
						id
						order
						name_of_bundle
						documents {
							id
							name
							file {
								publicURL
								prettySize
								extension
							}
						}
					}
				}
			}
		`}
		render={data => <OpenInfo data={data} {...props} />}
	/>
);
