import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import "./circle-plus.scss";

const CirclePlus = ({ className }) => {
	return (
		<div className={cn("circle-plus", className)}>
			<div className="circle-plus__circle">
				<div className="circle-plus__circle__horizontal" />
				<div className="circle-plus__circle__vertical" />
			</div>
		</div>
	);
};

CirclePlus.propTypes = {
	className: PropTypes.string
};

export default CirclePlus;
